import React, { useEffect, useLayoutEffect, useState } from 'react';
import { clientsApi } from '../api';
import {
  Button,
  Flex,
  Radio,
  Input,
  notification,
  Select,
  DatePicker,
} from 'antd';
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import './styles.css';
import dayjs from 'dayjs';
import GetRole from '../../GetRole.utils';
import {
  convertToCamelCase,
  convertFirstLetterToCamelCase,
  convertDate,
} from './../other/usefulFunctions.js';

const { TextArea } = Input;

const clientInitialState = {
  seId: 1,
  legalEntityName: '',
  address: '',
  city: '',
  state: '',
  country: '',
  pinCode: '',
  GSTINDetails: [
    {
      location: '',
      address: '',
      GSTIN: '',
      city: '',
      state: '',
      country: '',
      pinCode: '',
    },
  ],
  TANNumber: '',
  TaxId: '',
  suggestedInvoiceDate: '',
  poc: [
    {
      pocType: 'Finance',
      pocName: '',
      pocEmail: '',
      pocNumber: '',
    },
  ],
};

const AddClientPopup = ({
  hide,
  buttonText,
  clientId,
  SaveisModalOpen,
  clearForm,
  apiHit,
}) => {
  const [client, setClient] = useState(clientInitialState);
  const [validationErrors, setValidationErrors] = useState({});
  const [loadings, setLoadings] = useState(false);
  const alphaRegex = /^[a-zA-Z\s]+$/;
  const alphaNumericRegex = /^[a-zA-Z0-9\s]+$/;
  const regex = /^[a-zA-Z0-9]+$/;
  const gstRegex = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[0-9]{1}[A-Z]{1}[0-9]{1}$/;
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,7}$/;
  const numberRegex = /^[0-9]+$/;

  useEffect(() => {
    if (clientId) {
      clientsApi
        .getClientData(clientId, '')
        .then((res) => {
          const data = res.data.data[0];

          let obj = {
            seId: data ? data.seId : '',
            legalEntityName: data ? data.legalEntityName : '',
            address: data ? data.address : '',
            city: data ? data.city : '',
            state: data ? data.state : '',
            country: data ? data.country : '',
            pinCode: data ? data.pinCode : '',
            TANNumber: data ? data.TANNumber : '',
            TaxId: data ? data.TaxId : '',
            GSTINDetails:
              data && data?.GSTINDetails && data?.GSTINDetails.length > 0
                ? data?.GSTINDetails.map((e) => {
                    return {
                      address: e.address,
                      GSTIN: e.GSTIN,
                      location: e.location,
                      city: e.city,
                      state: e.state,
                      country: e.country,
                      pinCode: e.pinCode,
                    };
                  })
                : [
                    {
                      location: '',
                      address: '',
                      GSTIN: '',
                      city: '',
                      state: '',
                      country: '',
                      pinCode: '',
                    },
                  ],
            suggestedInvoiceDate: data
              ? convertDate(data.suggestedInvoiceDate)
              : '',
            poc:
              data && data?.poc && data?.poc.length > 0
                ? data?.poc.map((poc) => {
                    return {
                      pocType: poc.pocType,
                      pocName: poc.pocName,
                      pocEmail: poc.pocEmail,
                      pocNumber: poc.pocNumber,
                    };
                  })
                : [
                    {
                      pocType: 'Finance',
                      pocName: '',
                      pocEmail: '',
                      pocNumber: '',
                    },
                  ],
          };
          setClient({ ...obj });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [clientId, SaveisModalOpen, apiHit]);

  useEffect(() => {
    clearAllForm();
  }, [clearForm]);

  const openNotificationWithIcon = (type, message) => {
    notification[type]({
      message: message,
      style: {
        padding: '9px 24px',
      },
    });
  };

  const handleSeIdChange = (e) => {
    const { value } = e.target;
    if (value == 0) {
      const reset = {
        GSTINDetails: [
          {
            location: '',
            address: '',
            GSTIN: '',
            city: '',
            state: '',
            country: '',
            pinCode: '',
          },
        ],
        TANNumber: '',
      };
      setClient((prevClient) => ({ ...prevClient, ...reset, seId: value }));
      setValidationErrors({});
    } else if (value == 1) {
      const reset = {
        address: '',
        city: '',
        state: '',
        country: '',
        zipCode: '',
        TaxId: '',
      };
      setClient((prevClient) => ({ ...prevClient, ...reset, seId: value }));
      setValidationErrors({});
    } else {
      setClient((prevClient) => ({ ...prevClient, seId: value }));
      setValidationErrors({});
    }
  };

  const handleLegalEntityNameChange = (e) => {
    const { value } = e.target;
    const camelCaseValue = convertToCamelCase(value);
    if (
      camelCaseValue === '' ||
      (alphaNumericRegex.test(camelCaseValue) && camelCaseValue.length < 30)
    ) {
      setClient({ ...client, legalEntityName: camelCaseValue });
    }

    if (validationErrors) {
      setValidationErrors({ ...validationErrors, legalEntityName: '' });
    }
  };

  const handleGSTLocationChange = (e, index) => {
    const { value } = e.target;
    const camelCaseValue = convertFirstLetterToCamelCase(value);
    let temp = [...client.GSTINDetails];
    if (
      camelCaseValue === '' ||
      (alphaRegex.test(camelCaseValue) && camelCaseValue.length < 20)
    ) {
      temp[index].location = camelCaseValue;
      setClient({ ...client, GSTINDetails: temp });
    }

    if (validationErrors.GSTINDetails) {
      let validate = { ...validationErrors };
      if (
        validate.GSTINDetails[index] &&
        validate.GSTINDetails[index]['location']
      ) {
        validate.GSTINDetails[index]['location'] = '';
        setValidationErrors(validate);
      }
    }
  };

  const handleGSTINChange = (e, index) => {
    const { value } = e.target;
    const camelCaseValue = convertToCamelCase(value);
    let temp = [...client.GSTINDetails];

    if (
      camelCaseValue === '' ||
      (regex.test(camelCaseValue) && camelCaseValue.length <= 15)
    ) {
      temp[index].GSTIN = camelCaseValue;
      setClient({ ...client, GSTINDetails: temp });
    }

    if (validationErrors.GSTINDetails) {
      let validate = { ...validationErrors };
      if (
        validate.GSTINDetails[index] &&
        validate.GSTINDetails[index]['GSTIN']
      ) {
        validate.GSTINDetails[index]['GSTIN'] = '';
        setValidationErrors(validate);
      }
    }
  };

  const handleGSTAddressChange = (e, index) => {
    const { value } = e.target;
    let temp = [...client.GSTINDetails];
    temp[index].address = value;
    setClient({ ...client, GSTINDetails: temp });

    if (validationErrors.GSTINDetails) {
      let validate = { ...validationErrors };
      if (
        validate.GSTINDetails[index] &&
        validate.GSTINDetails[index]['address']
      ) {
        validate.GSTINDetails[index]['address'] = '';
        setValidationErrors(validate);
      }
    }
  };

  const handleGSTCityChange = (e, index) => {
    const { value } = e.target;
    const camelCaseValue = convertFirstLetterToCamelCase(value);
    let temp = [...client.GSTINDetails];
    if (
      camelCaseValue === '' ||
      (alphaRegex.test(camelCaseValue) && camelCaseValue.length < 20)
    ) {
      temp[index].city = camelCaseValue;
      setClient({ ...client, GSTINDetails: temp });
    }

    if (validationErrors.GSTINDetails) {
      let validate = { ...validationErrors };
      if (
        validate.GSTINDetails[index] &&
        validate.GSTINDetails[index]['city']
      ) {
        validate.GSTINDetails[index]['city'] = '';
        setValidationErrors(validate);
      }
    }
  };

  const handleGSTStateChange = (e, index) => {
    const { value } = e.target;
    const camelCaseValue = convertFirstLetterToCamelCase(value);
    let temp = [...client.GSTINDetails];
    if (
      camelCaseValue === '' ||
      (alphaRegex.test(camelCaseValue) && camelCaseValue.length < 20)
    ) {
      temp[index].state = camelCaseValue;
      setClient({ ...client, GSTINDetails: temp });
    }

    if (validationErrors.GSTINDetails) {
      let validate = { ...validationErrors };
      if (
        validate.GSTINDetails[index] &&
        validate.GSTINDetails[index]['state']
      ) {
        validate.GSTINDetails[index]['state'] = '';
        setValidationErrors(validate);
      }
    }
  };

  const handleGSTCountryChange = (e, index) => {
    const { value } = e.target;
    const camelCaseValue = convertFirstLetterToCamelCase(value);
    let temp = [...client.GSTINDetails];
    if (
      camelCaseValue === '' ||
      (alphaRegex.test(camelCaseValue) && camelCaseValue.length < 20)
    ) {
      temp[index].country = camelCaseValue;
      setClient({ ...client, GSTINDetails: temp });
    }

    if (validationErrors.GSTINDetails) {
      let validate = { ...validationErrors };
      if (
        validate.GSTINDetails[index] &&
        validate.GSTINDetails[index]['country']
      ) {
        validate.GSTINDetails[index]['country'] = '';
        setValidationErrors(validate);
      }
    }
  };

  const handleGSTPinCodeChange = (e, index) => {
    const { value } = e.target;
    let temp = [...client.GSTINDetails];
    if (value.length <= 6) {
      temp[index].pinCode = value;
      setClient({ ...client, GSTINDetails: temp });
    }

    if (validationErrors.GSTINDetails) {
      let validate = { ...validationErrors };
      if (
        validate.GSTINDetails[index] &&
        validate.GSTINDetails[index]['pinCode']
      ) {
        validate.GSTINDetails[index]['pinCode'] = '';
        setValidationErrors(validate);
      }
    }
  };

  const handleGSTINAdd = () => {
    let tempClient = [...client.GSTINDetails];

    const hasEmptyFields = tempClient.some(
      (GSTINDetail) => !GSTINDetail.location || !GSTINDetail.address 
                || !GSTINDetail.city || !GSTINDetail.state || !GSTINDetail.country || !GSTINDetail.pinCode
    );

    if (hasEmptyFields) {
      return;
    }

    tempClient.push({
      location: '',
      address: '',
      GSTIN: '',
      city: '',
      state: '',
      country: '',
      pinCode: '',
    });
    setClient({ ...client, GSTINDetails: tempClient });
  };

  const handleGSTINDelete = (index) => {
    let tempClient = [...client.GSTINDetails];
    tempClient.splice(index, 1);
    setClient({ ...client, GSTINDetails: tempClient });
  };

  const handleAddressChange = (e) => {
    const { value } = e.target;
    setClient({ ...client, address: value });

    if (validationErrors) {
      setValidationErrors({ ...validationErrors, address: '' });
    }
  };

  const handleCityChange = (e) => {
    const { value } = e.target;
    const camelCaseValue = convertFirstLetterToCamelCase(value);
    if (
      camelCaseValue === '' ||
      (alphaRegex.test(camelCaseValue) && camelCaseValue.length < 20)
    ) {
      setClient({ ...client, city: camelCaseValue });
    }

    if (validationErrors) {
      setValidationErrors({ ...validationErrors, city: '' });
    }
  };

  const handleStateChange = (e) => {
    const { value } = e.target;
    const camelCaseValue = convertFirstLetterToCamelCase(value);
    if (
      camelCaseValue === '' ||
      (alphaRegex.test(camelCaseValue) && camelCaseValue.length < 20)
    ) {
      setClient({ ...client, state: camelCaseValue });
    }

    if (validationErrors) {
      setValidationErrors({ ...validationErrors, state: '' });
    }
  };

  const handleCountryChange = (e) => {
    const { value } = e.target;
    const camelCaseValue = convertFirstLetterToCamelCase(value);
    if (
      camelCaseValue === '' ||
      (alphaRegex.test(camelCaseValue) && camelCaseValue.length < 20)
    ) {
      setClient({ ...client, country: camelCaseValue });
    }

    if (validationErrors) {
      setValidationErrors({ ...validationErrors, country: '' });
    }
  };

  const handleZipCodeChange = (e) => {
    const { value } = e.target;
    if (value.length <= 6) {
      setClient({ ...client, pinCode: value });
    }

    if (validationErrors) {
      setValidationErrors({ ...validationErrors, pinCode: '' });
    }
  };

  const handleTANNumberChange = (e) => {
    const { value } = e.target;
    const camelCaseValue = convertToCamelCase(value);
    if (
      camelCaseValue === '' ||
      (regex.test(camelCaseValue) && camelCaseValue.length <= 10)
    ) {
      setClient({ ...client, TANNumber: camelCaseValue });
    }

    if (validationErrors) {
      setValidationErrors({ ...validationErrors, TANNumber: '' });
    }
  };

  const handleTaxIdChange = (e) => {
    const { value } = e.target;
    const camelCaseValue = convertToCamelCase(value);
    if (camelCaseValue === '' || regex.test(camelCaseValue)) {
      setClient({ ...client, TaxId: camelCaseValue });
    }

    if (validationErrors) {
      setValidationErrors({ ...validationErrors, TaxId: '' });
    }
  };

  const handleSuggestedInvoiceDateChange = (dates) => {
    setClient((prevClient) => ({
      ...prevClient,
      suggestedInvoiceDate: dates ? dayjs(dates) : null,
    }));

    if (validationErrors) {
      setValidationErrors({ ...validationErrors, suggestedInvoiceDate: '' });
    }
  };

  const handlePocTypeChange = (value, index) => {
    let temp = [...client.poc];
    temp[index].pocType = value;
    setClient({ ...client, poc: temp });

    if (validationErrors.poc) {
      let validate = { ...validationErrors };
      if (validate.poc[index] && validate.poc[index]['pocType']) {
        validate.poc[index]['pocType'] = '';
        setValidationErrors(validate);
      }
    }
  };

  const handlePocNameChange = (e, index) => {
    const { value } = e.target;
    const camelCaseValue = convertFirstLetterToCamelCase(value);
    let temp = [...client.poc];
    if (
      camelCaseValue === '' ||
      (alphaRegex.test(camelCaseValue) && camelCaseValue.length < 35)
    ) {
      temp[index].pocName = camelCaseValue;
      setClient({ ...client, poc: temp });
    }

    if (validationErrors.poc) {
      let validate = { ...validationErrors };
      if (validate.poc[index] && validate.poc[index]['pocName']) {
        validate.poc[index]['pocName'] = '';
        setValidationErrors(validate);
      }
    }
  };

  const handlePocEmailChange = (e, index) => {
    const { value } = e.target;
    let temp = [...client.poc];
    temp[index].pocEmail = value;
    setClient({ ...client, poc: temp });

    if (validationErrors.poc) {
      let validate = { ...validationErrors };
      if (validate.poc[index] && validate.poc[index]['pocEmail']) {
        validate.poc[index]['pocEmail'] = '';
        setValidationErrors(validate);
      }
    }
  };

  const handlePocNumberChange = (e, index) => {
    const { value } = e.target;
    let temp = [...client.poc];
    if (value === '' || (numberRegex.test(value) && value.length <= 15)) {
      temp[index].pocNumber = value;
      setClient({ ...client, poc: temp });
    }

    if (validationErrors.poc) {
      let validate = { ...validationErrors };
      if (validate.poc[index] && validate.poc[index]['pocNumber']) {
        validate.poc[index]['pocNumber'] = '';
        setValidationErrors(validate);
      }
    }
  };

  const handleAddPocDetails = () => {
    let tempClient = [...client.poc];

    const hasEmptyFields = tempClient.some(
      (poc) => !poc.pocType || !poc.pocName || !poc.pocEmail || !poc.pocNumber
    );
  
    if (hasEmptyFields) {
      return;
    }

    tempClient.push({
      pocType: 'Finance',
      pocName: '',
      pocEmail: '',
      pocNumber: '',
    });
    setClient({ ...client, poc: tempClient });
    
  };

  const handlePocDetailsDelete = (index) => {
    let tempClient = [...client.poc];
    tempClient.splice(index, 1);
    setClient({ ...client, poc: tempClient });
  };

  const filterEmptyValues = (obj) => {
    const newObj = Array.isArray(obj) ? [] : {};
    Object.keys(obj).forEach((key) => {
      if (Array.isArray(obj[key])) {
        newObj[key] = obj[key].map((item) => filterEmptyValues(item));
      } else if (
        obj[key] !== '' &&
        obj[key] !== null &&
        obj[key] !== undefined
      ) {
        newObj[key] = obj[key];
      }
    });
    return newObj;
  };

  function clearAllForm() {
    const clearField = {
      seId: 1,
      legalEntityName: '',
      address: '',
      city: '',
      state: '',
      country: '',
      pinCode: '',
      GSTINDetails: [
        {
          location: '',
          address: '',
          GSTIN: '',
          city: '',
          state: '',
          country: '',
          pinCode: '',
        },
      ],
      TANNumber: '',
      TaxId: '',
      suggestedInvoiceDate: '',
      poc: [
        {
          pocType: 'Finance',
          pocName: '',
          pocEmail: '',
          pocNumber: '',
        },
      ],
    };

    setClient({ ...client, ...clearField });
    setValidationErrors({});
  }

  const validateHandler = () => {
    let errorMessages = {};
    let missingFields = [];
    let isValid = true;

    if (client.legalEntityName.trim() === '') {
      errorMessages.legalEntityName = 'Legal Entity Name is required';
      missingFields.push('Legal Entity Name');
      isValid = false;
    }

    if (client.seId === 0) {
      if (client.address.trim() === '') {
        errorMessages.address = 'Address is required';
        missingFields.push('Address');
        isValid = false;
      }

      if (client.city.trim() === '') {
        errorMessages.city = 'City is required';
        missingFields.push('City');
        isValid = false;
      }

      if (client.state.trim() === '') {
        errorMessages.state = 'State is required';
        missingFields.push('State');
        isValid = false;
      }

      if (client.country.trim() === '') {
        errorMessages.country = 'Country is required';
        missingFields.push('Country');
        isValid = false;
      }

      if (client.pinCode === '') {
        errorMessages.pinCode = 'Pin Code is required';
        missingFields.push('Pin Code');
        isValid = false;
      }

      if (client.pinCode.length < 6) {
        errorMessages.pinCode = 'Pin Code should be 6 digits';
        missingFields.push('Pin Code');
        isValid = false;
      }

      if (client.TaxId.trim() === '') {
        errorMessages.TaxId = 'Tax Id is required';
        missingFields.push('Tax Id');
        isValid = false;
      }
    }

    if (client.seId === 1) {
      if (client.GSTINDetails.length > 0) {
        let gstErrors = [];
        let temp = [...client.GSTINDetails];
        temp.forEach((item, index) => {
          let gstError = {};

          if (item.location.trim() === '') {
            gstError.location = 'Location is required';
            missingFields.push(`Location ${index + 1}`);
            isValid = false;
          }

          if (item.GSTIN && !gstRegex.test(item.GSTIN)) {
            gstError.GSTIN = 'GSTIN Number Invalid';
            missingFields.push(`GSTIN ${index + 1}`);
            isValid = false;
          }        

          if (item.address.trim() === '') {
            gstError.address = 'Address is required';
            missingFields.push(`Address ${index + 1}`);
            isValid = false;
          }

          if (item.city.trim() === '') {
            gstError.city = 'City is required';
            missingFields.push(`City ${index + 1}`);
            isValid = false;
          }

          if (item.state.trim() === '') {
            gstError.state = 'State is required';
            missingFields.push(`State ${index + 1}`);
            isValid = false;
          }

          if (item.country.trim() === '') {
            gstError.country = 'Country is required';
            missingFields.push(`Country ${index + 1}`);
            isValid = false;
          }

          if (item.pinCode === '') {
            gstError.pinCode = 'Pin Code is required';
            missingFields.push(`PinCode ${index + 1}`);
            isValid = false;
          }

          if (item.pinCode.length < 6) {
            gstError.pinCode = 'Pin Code should be 6 digits';
            missingFields.push(`PinCode ${index + 1}`);
            isValid = false;
          }

          gstErrors.push(gstError);
        });
        errorMessages.GSTINDetails = gstErrors;
      }

      if (client.TANNumber.trim() === '') {
        errorMessages.TANNumber = 'TAN Number is required';
        missingFields.push('TAN Number');
        isValid = false;
      }

      if (client.TANNumber.length < 10) {
        errorMessages.TANNumber = 'TAN Number should be 10 digits';
        missingFields.push('TAN Number');
        isValid = false;
      }
    }

    if (client.suggestedInvoiceDate === '') {
      errorMessages.suggestedInvoiceDate = 'Suggested Invoice Date is required';
      missingFields.push('Suggested Invoice Date');
      isValid = false;
    }

    if (client.poc.length > 0) {
      let pocErrors = [];
      let temp = [...client.poc];
      temp.forEach((item, index) => {
        let pocError = {};

        if (item.pocType.trim() === '') {
          pocError.pocType = 'Poc Type is required';
          missingFields.push(`Poc Type ${index + 1}`);
          isValid = false;
        }

        if (item.pocName.trim() === '') {
          pocError.pocName = 'Poc Name is required';
          missingFields.push(`Poc Name ${index + 1}`);
          isValid = false;
        }

        if (item.pocEmail.trim() === '') {
          pocError.pocEmail = 'Poc Email is required';
          missingFields.push(`Poc Email ${index + 1}`);
          isValid = false;
        }

        if (!emailRegex.test(item.pocEmail)) {
          pocError.pocEmail = 'Poc Email is invalid';
          missingFields.push(`Poc Email ${index + 1}`);
          isValid = false;
        }

        if (item.pocNumber.trim() === '') {
          pocError.pocNumber = 'Poc Number is required';
          missingFields.push(`Poc Number ${index + 1}`);
          isValid = false;
        }

        if (item.pocNumber.length < 10) {
          pocError.pocNumber = 'Poc Number should be minimum 10 digits';
          missingFields.push(`Poc Number ${index + 1}`);
          isValid = false;
        }
        pocErrors.push(pocError);
      });
      errorMessages.poc = pocErrors;
    }

    setValidationErrors(errorMessages);

    // if (!isValid) {
    //   const errorMessage = `Please fill in the required field`;
    //   notification.error({
    //     message: '',
    //     description: errorMessage,
    //     duration: 2,
    //   });
    // }

    return isValid;
  };

  const handleSubmit = () => {
    setLoadings(true);
    if (validateHandler()) {
      try {
        const postData = filterEmptyValues(client);
        if (clientId) {
          clientsApi
            .updateClientData(clientId, postData)
            .then((res) => {
              console.log(res);
              setLoadings(false);
              openNotificationWithIcon(
                'success',
                'Client updated successfully.'
              );
              hide();
            })
            .catch((err) => {
              setLoadings(false);
              console.log(err);
            });
        } else {
          clientsApi
            .addClientData(postData)
            .then((res) => {
              console.log(res);
              setLoadings(false);
              openNotificationWithIcon(
                'success',
                'Client created successfully.'
              );
              hide();
            })
            .catch((err) => {
              setLoadings(false);
              console.log(err);
            });
        }
      } catch (error) {
        setLoadings(false);
        console.log(error);
      }
    } else {
      setLoadings(false);
      console.log('fields empty');
    }
  };

  return (
    <>
      <div className="popupDiv" style={{ height: '60vh', overflow: 'auto' }}>
        <Flex justify="center" style={{ paddingTop: '8px' }}>
          <Radio.Group
            name="seId"
            defaultValue={1}
            value={client.seId}
            onChange={(e) => handleSeIdChange(e)}
          >
            <Radio.Button value={0}>Spanidea Systems LLC</Radio.Button>
            <Radio.Button value={1}>Spanidea Systems Pvt. Ltd.</Radio.Button>
          </Radio.Group>
        </Flex>

        <Flex vertical gap="10" style={{ width: '100%' }}>
          <span>
            Legal Entity Name
            <span style={{ color: 'red', fontSize: '15px' }}>*</span>
          </span>
          <Input
            size="large"
            placeholder="Legal Entity Name"
            value={client.legalEntityName}
            onChange={(e) => handleLegalEntityNameChange(e)}
            className={validationErrors.legalEntityName ? 'error' : ''}
          />
        </Flex>

        {client.seId === 1 ? (
          <div>
            <div>
              <Flex vertical gap="10px" style={{ paddingTop: '28px' }}>
                {client.GSTINDetails.map((GSTINDetail, index) => (
                  <Flex
                    vertical
                    gap="10px"
                    style={{
                      padding: '4px 15px',
                      marginLeft: '2px',
                      boxShadow:
                        'rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px',
                    }}
                    key={index}
                  >
                    <Flex
                      vertical
                      gap="10px"
                      style={{ width: '100%', paddingBottom: '10px' }}
                    >
                      <Flex
                        horizontal
                        style={{
                          alignItems: 'center',
                          justifyContent: 'space-between',
                        }}
                      >
                        <span>
                          Location {index + 1}
                          {
                            <span style={{ color: 'red', fontSize: '15px' }}>
                              *
                            </span>
                          }
                        </span>

                        {client.GSTINDetails.length > 1 && (
                          <Button
                            type="link"
                            size="large"
                            style={{ padding: '0' }}
                            onClick={() => handleGSTINDelete(index)}
                            danger
                          >
                            <DeleteOutlined />
                          </Button>
                        )}
                      </Flex>
                      <Flex
                        gap="10px"
                        style={{
                          width: '100%',
                          marginTop: '-10px',
                          paddingTop: '13px',
                        }}
                      >
                        <Input
                          size="large"
                          placeholder="Location"
                          value={GSTINDetail.location}
                          onChange={(e) => handleGSTLocationChange(e, index)}
                          className={
                            validationErrors.GSTINDetails &&
                            validationErrors.GSTINDetails[index] &&
                            validationErrors.GSTINDetails[index].location
                              ? 'error'
                              : ''
                          }
                        />
                        <Input
                          size="large"
                          placeholder="GSTIN (e.g. 08AAPCS5825D1Z0)"
                          value={GSTINDetail.GSTIN}
                          onChange={(e) => handleGSTINChange(e, index)}
                          className={
                            validationErrors.GSTINDetails &&
                            validationErrors.GSTINDetails[index] &&
                            validationErrors.GSTINDetails[index].GSTIN
                              ? 'error'
                              : ''
                          }
                        />
                      </Flex>
                      <TextArea
                        style={{ overflow: 'auto' }}
                        autosize={{ minRows: 2, maxRows: 6 }}
                        placeholder="Address"
                        value={GSTINDetail.address}
                        onChange={(e) => handleGSTAddressChange(e, index)}
                        className={
                          validationErrors.GSTINDetails &&
                          validationErrors.GSTINDetails[index] &&
                          validationErrors.GSTINDetails[index].address
                            ? 'error'
                            : ''
                        }
                      />
                      <Flex
                        gap="10px"
                        style={{
                          width: '100%',
                          marginTop: '-10px',
                          paddingTop: '13px',
                        }}
                      >
                        <Input
                          size="large"
                          placeholder="City"
                          value={GSTINDetail.city}
                          onChange={(e) => handleGSTCityChange(e, index)}
                          className={
                            validationErrors.GSTINDetails &&
                            validationErrors.GSTINDetails[index] &&
                            validationErrors.GSTINDetails[index].city
                              ? 'error'
                              : ''
                          }
                        />

                        <Input
                          size="large"
                          placeholder="State"
                          value={GSTINDetail.state}
                          onChange={(e) => handleGSTStateChange(e, index)}
                          className={
                            validationErrors.GSTINDetails &&
                            validationErrors.GSTINDetails[index] &&
                            validationErrors.GSTINDetails[index].state
                              ? 'error'
                              : ''
                          }
                        />
                      </Flex>
                      <Flex
                        gap="10px"
                        style={{
                          width: '100%',
                          marginTop: '-10px',
                          paddingTop: '13px',
                        }}
                      >
                        <Input
                          size="large"
                          placeholder="Country"
                          value={GSTINDetail.country}
                          onChange={(e) => handleGSTCountryChange(e, index)}
                          className={
                            validationErrors.GSTINDetails &&
                            validationErrors.GSTINDetails[index] &&
                            validationErrors.GSTINDetails[index].country
                              ? 'error'
                              : ''
                          }
                        />
                        <Input
                          size="large"
                          minLength={6}
                          maxLength={6}
                          placeholder="Pincode"
                          type="number"
                          value={GSTINDetail.pinCode}
                          onChange={(e) => handleGSTPinCodeChange(e, index)}
                          className={
                            validationErrors.GSTINDetails &&
                            validationErrors.GSTINDetails[index] &&
                            validationErrors.GSTINDetails[index].pinCode
                              ? 'error'
                              : ''
                          }
                        />
                      </Flex>
                    </Flex>
                  </Flex>
                ))}
              </Flex>

              <Flex
                vertical
                gap=""
                style={{
                  width: '100%',
                  paddingBottom: '10px',
                  paddingTop: client.GSTINDetails.length > 0 ? '15px' : '',
                  alignItems: 'end',
                }}
              >
                <Button
                  type="link"
                  style={{
                    padding: '0',
                    border: '1px solid',
                    width: '20%',
                  }}
                  onClick={() => handleGSTINAdd()}
                >
                  <PlusOutlined />
                  Add Location
                </Button>
              </Flex>
            </div>
          </div>
        ) : (
          <div>
            <Flex vertical style={{ width: '100%', paddingTop: '25px' }}>
              <span>
                Address
                {<span style={{ color: 'red', fontSize: '15px' }}>*</span>}
              </span>

              <TextArea
                style={{ overflow: 'auto' }}
                autosize={{ minRows: 2, maxRows: 6 }}
                name="address"
                placeholder="Address"
                value={client.address}
                onChange={(e) => handleAddressChange(e)}
                className={validationErrors.address ? 'error' : ''}
              />
              <Flex gap="10px" style={{ width: '100%', marginTop: '20px' }}>
                <Input
                  size="large"
                  name="city"
                  placeholder="City"
                  value={client.city}
                  onChange={(e) => handleCityChange(e)}
                  className={validationErrors.city ? 'error' : ''}
                />
                <Input
                  size="large"
                  name="state"
                  placeholder="State"
                  value={client.state}
                  onChange={(e) => handleStateChange(e)}
                  className={validationErrors.state ? 'error' : ''}
                />
              </Flex>
              <Flex gap="10px" style={{ width: '100%', marginTop: '20px' }}>
                <Input
                  size="large"
                  name="country"
                  placeholder="Country"
                  value={client.country}
                  onChange={(e) => handleCountryChange(e)}
                  className={validationErrors.country ? 'error' : ''}
                />
                <Input
                  size="large"
                  type="number"
                  minLength={6}
                  maxLength={6}
                  name="zipCode"
                  placeholder="Zip Code"
                  value={client.pinCode}
                  onChange={(e) => handleZipCodeChange(e)}
                  className={validationErrors.pinCode ? 'error' : ''}
                />
              </Flex>
            </Flex>
          </div>
        )}

        <Flex style={{ width: '100%', marginTop: '20px' }} gap={10}>
          {client.seId === 1 ? (
            <Flex vertical style={{ width: '100%' }}>
              <span>
                TAN Number
                {<span style={{ color: 'red', fontSize: '15px' }}>*</span>}
              </span>
              <Input
                size="large"
                name="TANNumber"
                placeholder="TAN Number (e.g. PNEA18234G)"
                value={client.TANNumber}
                onChange={(e) => handleTANNumberChange(e)}
                className={validationErrors.TANNumber ? 'error' : ''}
              />
            </Flex>
          ) : (
            <Flex vertical style={{ width: '100%' }}>
              <span>
                Tax ID
                {<span style={{ color: 'red', fontSize: '15px' }}>*</span>}
              </span>

              <Input
                size="large"
                name="TaxId"
                placeholder="Tax ID"
                value={client.TaxId}
                onChange={(e) => handleTaxIdChange(e)}
                className={validationErrors.TaxId ? 'error' : ''}
              />
            </Flex>
          )}

          <Flex vertical style={{ width: '100%' }}>
            <span>
              Suggested Invoice Date (DD)
              {<span style={{ color: 'red', fontSize: '15px' }}>*</span>}
            </span>

            <DatePicker
              // className="no-clear-button"
              size="large"
              style={{ width: '100%' }}
              format={'DD'}
              allowClear={false}
              inputReadOnly={true}
              value={
                client.suggestedInvoiceDate
                  ? dayjs(client.suggestedInvoiceDate)
                  : null
              }
              onChange={(dates) => handleSuggestedInvoiceDateChange(dates)}
              className={validationErrors.suggestedInvoiceDate ? 'error' : ''}
            />
          </Flex>
        </Flex>

        <Flex
          vertical
          justify="space-between"
          gap="10px"
          style={{ width: '100%', marginTop: '20px' }}
        >
          <span style={{ width: '100%' }}>
            Point Of Contact(s)
            {<span style={{ color: 'red', fontSize: '15px' }}>*</span>}
          </span>
          <Flex
            vertical
            style={{
              padding: '4px 4px 12px 7px',
              marginLeft: '1px',
              boxShadow:
                'rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px',
            }}
          >
            {client.poc.map((pocDetail, index) => (
              <Flex
                horizontal
                gap="10px"
                style={{ width: '100%', marginTop: '10px' }}
                key={index}
              >
                <Select
                  style={{ width: '100%', marginLeft:'10px' }}
                  size="large"
                  placeholder="Type"
                  optionFilterProp="children"
                  value={pocDetail.pocType}
                  onChange={(value) => handlePocTypeChange(value, index)}
                  className={
                    validationErrors.poc &&
                    validationErrors.poc[index] &&
                    validationErrors.poc[index].pocType
                      ? 'error'
                      : ''
                  }
                  options={[
                    {
                      value: 'Finance',
                      label: 'Finance',
                    },
                    {
                      value: 'Sales',
                      label: 'Sales',
                    },
                    {
                      value: 'Admin',
                      label: 'Admin',
                    },
                    {
                      value: 'Other',
                      label: 'Other',
                    },
                  ]}
                />
                <Input
                  size="large"
                  placeholder="Name"
                  value={pocDetail.pocName}
                  onChange={(e) => handlePocNameChange(e, index)}
                  className={
                    validationErrors.poc &&
                    validationErrors.poc[index] &&
                    validationErrors.poc[index].pocName
                      ? 'error'
                      : ''
                  }
                />

                <Input
                  type="email"
                  size="large"
                  placeholder="Email"
                  value={pocDetail.pocEmail}
                  onChange={(e) => handlePocEmailChange(e, index)}
                  className={
                    validationErrors.poc &&
                    validationErrors.poc[index] &&
                    validationErrors.poc[index].pocEmail
                      ? 'error'
                      : ''
                  }
                />
                <Input
                style={{marginRight: client.poc.length === 1 ? '10px': '0'}}
                  type="text"
                  size="large"
                  placeholder="Mobile No."
                  minLength={10}
                  maxLength={15}
                  value={pocDetail.pocNumber}
                  onChange={(e) => handlePocNumberChange(e, index)}
                  className={
                    validationErrors.poc &&
                    validationErrors.poc[index] &&
                    validationErrors.poc[index].pocNumber
                      ? 'error'
                      : ''
                  }
                  onKeyDown={(e) => {
                    if (
                      !/[\d\b+]/.test(e.key) &&
                      ![
                        'ArrowLeft',
                        'ArrowRight',
                        'Delete',
                        'Backspace',
                      ].includes(e.key) &&
                      !(e.ctrlKey && e.key === 'r') &&
                      !(e.ctrlKey && e.key === 'a')
                    )
                      e.preventDefault();
                  }}
                />
                {client.poc.length > 1 && (
                  <Button
                    type="link"
                    size="large"
                    style={{ paddingRight: '0' }}
                    onClick={() => handlePocDetailsDelete(index)}
                    danger
                    className='deleteButtonInPOC'
                  >
                    <DeleteOutlined />
                  </Button>
                )}
              </Flex>
            ))}
          </Flex>
        </Flex>
        <Flex
          vertical
          gap=""
          style={{
            width: '100%',
            paddingBottom: '10px',
            paddingTop: client?.poc?.length > 0 ? '15px' : '',
            alignItems: 'end',
          }}
        >
          <Button
            type="link"
            style={{
              padding: '0',
              border: '1px solid',
              width: '15%',
            }}
            onClick={() => handleAddPocDetails()}
          >
            <PlusOutlined />
            Add POC
          </Button>
        </Flex>
      </div>
      <Flex justify="end" style={{ marginRight: '17px', paddingTop: '18px' }}>
        <Button 
          type="primary" 
          htmlType="submit" 
          loading={loadings}
          onClick={handleSubmit}
        >
          {buttonText}
        </Button>
      </Flex>
    </>
  );
};

export default AddClientPopup;
